import './App.css';
import Navigation from './navigation/navigation';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import { AuthorizationProvider } from './services/auth/AuthorizationContext';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import CustomLoader from './components/common/loader/customLoader';
import { LoaderProvider } from './services/loader/loader';

const App = () => {

  useEffect(() => {
    // console.log(loading)
    console.log('app Component called')

  }, [])
  return (
    <main className="App">
      {/* <AuthorizationProvider>
        <Navigation />
        <ToastContainer />
      </AuthorizationProvider> */}
      <AuthorizationProvider>
        <LoaderProvider>
          <ToastContainer />
          <CustomLoader />
          <BrowserRouter>
            <Routes>
              <Route path='/*' element={<Navigation />}></Route>
            </Routes>
          </BrowserRouter>
        </LoaderProvider>
      </AuthorizationProvider>
    </main>
  );
}

export default App;


