import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Offer from "../../../assets/images/offer.png"
import './redeemNow.scss';
import { useNavigate, useSearchParams } from "react-router-dom";
import CommonHeader from "../../components/common/common-header/common-header";
import share from "../../../assets/images/share.png"
import tag from "../../../assets/images/LargeTag.png"
import { routerPath } from "../../navigation/path";
import { useEffect, useRef, useState } from "react";
import ApiService from "../../services/http/api-service";
import { ApiConfiguration } from "../../services/http/api-configuration";
import CommonToast from "../../components/common/toast/common-toast";

const RedeemNow = () => {
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const offerId = searchParams.get('offer_id')
    const [offerDetail, setOffetDetail] = useState([])
    const [isCommonToast, setCommonToast] = useState(false)
    const [toastMessege, settoastMessege] = useState('')

    const redeemOffer = () => {
        if (offerDetail.cap_limit_exceeded == 1) {
            settoastMessege(offerDetail.cap_limit_msg)
            setCommonToast(true)
            removeToast()
            return
        }
        let offer = {
            offer_code: offerDetail.offer_code,
            offer_id: offerDetail.offer_id,
            restaurant_code: offerDetail.restaurant_code,
            restaurant_id: offerDetail.restaurant_id,
            restaurant_offer_id: offerDetail.restaurant_offer_id,
            offer_name: offerDetail.offer_name
        }
        // navigate(`${routerPath.qrSuccess}?offer_detail=${encodeURIComponent(JSON.stringify(offer))}`)
        navigate(`${routerPath.welcomeVideo}?offer_detail=${encodeURIComponent(JSON.stringify(offer))}`)
    }

    const removeToast = () => {
        setTimeout(() => {
            setCommonToast(false)
        }, 3000)
    }

    const isApiLoadOnes = useRef(false)

    useEffect(() => {
        if (!isApiLoadOnes.current) {
            getOfferList()
            isApiLoadOnes.current = true
        }
    }, [])

    const getOfferList = () => {
        ApiService.get(ApiConfiguration.offerList + `?restaurant_code=${localStorage.getItem('restaurant_code')}&offer_id=${offerId}`).then(res => {
            console.log(res)
            setOffetDetail(res.data.data[0])
        }).catch(err => {
            console.log(err)
        })
    }

    const handleShare = async () => {
        const title = 'Share Image'
        const text = `${offerDetail.offer_name} *TnC Apply`
        const url = `https://www.carlshoppyhours.com/`


        if (navigator.share) {
            try {
                await navigator.share({ title, text, url });
                console.log('Shared successfully!');
            } catch (error) {
                console.error('Error sharing:', error);
            }
        } else {
            console.log('share will work on real devices')
        }
    };
    return (
        <>
            {
                isCommonToast &&
                <CommonToast message={toastMessege} />
            }
            <CommonHeader header={'Offer Details'}></CommonHeader>

            <Container className="px-0 redeem-now-page">
                <Row className="px-0">
                    <Col md={12} className="px-0">
                        <div className="image-wrapper">
                            <img src={offerDetail.offer_image} alt="" />
                            <div className="short-offer">
                                {offerDetail.short_description}

                            </div>
                        </div>
                    </Col>
                    <Col md={12} className="px-4">
                        <div className="restaurant-info">
                            <p className="resto-name">
                                {offerDetail.restaurant_name}
                            </p>
                            <span className="resto-location">
                                {offerDetail.city_name}
                            </span>
                        </div>

                        <div className="offer-info">
                            <p className="offer-name">
                                {offerDetail.offer_name}
                            </p>
                            <p className="offer-valid" dangerouslySetInnerHTML={{ __html: offerDetail.offer_discription }}>
                                {/* {offerDetail.offer_discription} */}

                            </p>

                        </div>
                        <div className="button-wrapper">
                            <button className="redeem-btn" onClick={redeemOffer}>
                                <img src={tag} alt="" />  Redeem now
                            </button>
                            <button className="share-btn" onClick={handleShare}>
                                <img src={share} alt="" /> Share with your friends
                            </button>
                        </div>
                        <div className="terms-condition">
                            <p>
                                By using this offer you are agreeing to the Terms and Conditions set forth by the restaurant.
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )

}

export default RedeemNow;