import { useContext, useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import './restaurant-code.scss'
import { ToastContainer, toast } from 'react-toastify';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import ApiService from "../../services/http/api-service";
import { ApiConfiguration } from "../../services/http/api-configuration";
import { useNavigate } from 'react-router-dom';
import logo from '../../../assets/images/Carlsberg-logo.png'
import CommonHeader from "../../components/common/common-header/common-header";
import { routerPath } from "../../navigation/path";
import CommonAlert from "../../components/common/common-alert/common-alert";
import Autocomplete from "../../components/common/autocomplete-search/AutoComplete";
import CommonToast from "../../components/common/toast/common-toast";
import React from "react";
import restocodeImg from '../../../assets/images/resto-code-img.png'
import SelectWithSearch from "../../components/common/select-with-search/SelectWithSearch";
import SearchCity from "../../components/common/search-city/SearchCity";


const RestaurantCode = () => {
    // const { setAuth } = useContext(AuthContext)
    const navigate = useNavigate()
    const [userList, setUserList] = useState([]);
    const [isErrorAlert, setErrorAlert] = useState(false);
    const [errorMessage, seterrorMessage] = useState('');
    const [restaurantCode, setRestaurantCode] = useState('')
    const [citys, setCitys] = useState([])
    const [cityId, setCityId] = useState('')



    useEffect(() => {
        navigate(routerPath.restaurantCode, { replace: true })
    }, [])

    const validationSchema = Yup.object().shape({
        restaurant_code: Yup.string()
            .required('Restaurent code is a mandatory field'),

    });

    const restaurantCodeError = (e) => {
        console.log(e.response.data.data.message)
        setErrorAlert(true)
        seterrorMessage(e.response.data.data.message)
        removeToast()

    }

    const formik = useFormik({
        initialValues: {
            restaurant_code: restaurantCode,


        },
        validationSchema,
        // validateOnChange: false,
        // validateOnBlur: false,
        onSubmit: (data) => {
            ApiService.get(ApiConfiguration.offerList + `?restaurant_code=${data.restaurant_code}&offer_id=`).then(res => {
                console.log(res.data.data)
                if (res.data.data.length == 0) {
                    setErrorAlert(true)
                    seterrorMessage(`No offer available for this restaurant`)
                    removeToast()

                    return
                }
                localStorage.setItem('restaurant_code', data.restaurant_code)
                // navigate(routerPath.welcomeVideo)
                navigate(routerPath.offers)
                formik.handleReset()
            }).catch(err => {

                console.log(err)
                setErrorAlert(true)
                seterrorMessage(err.response.data.data.message)
                removeToast()
            })
        },
    });

    const closeResendPopup = () => {
        setErrorAlert(false)
    }

    const restaurantSelected = (e) => {
        console.log(e.restaurant_code)
        formik.setFieldValue('restaurant_code', e.restaurant_code);

    }

    const removeToast = () => {

        setTimeout(() => {
            setErrorAlert(false)
        }, 3000);
    }



    // Attach the event listener to disable the back button
    // useEffect(() => {

    //     // Disable the browser's back button
    //     const handleBackButton = (e) => {
    //         // Do nothing when the back button is pressed
    //         e.preventDefault();
    //         navigate(routerPath.restaurantCode, { replace: true })
    //         console.log('back button pressed')
    //     };

    //     window.addEventListener('popstate', handleBackButton);

    //     return () => {
    //         // Clean up the event listener when the component unmounts
    //         window.removeEventListener('popstate', handleBackButton);
    //     };
    // }, []);






    return (
        <div>
            {
                isErrorAlert &&
                <CommonToast message={errorMessage} />
            }
            {/* {
                isErrorAlert &&
                <CommonAlert messege={errorMessage} btn2={`OK`} btn2ClickEvent={closeResendPopup} isSingleBtn={true}></CommonAlert>
            } */}
            {/* <CommonHeader header={'Restaurant code'}></CommonHeader> */}

            <Container className=" mx-0 restaurant-code-page" fluid>
                <Row>
                    <Col md={12} className="px-0">
                        <div className="logo-wrapper">
                            <img src={restocodeImg} alt="" />
                        </div>
                        <div className="instruction-wrapper">
                            <p className="heading-text">
                                Enter restaurant details
                            </p>

                        </div>
                        <div className="right-container">
                            <div className="form-wrapper">
                                <form onSubmit={formik.handleSubmit}>

                                    <div className="form-group">
                                        <label htmlFor="">If you already know the Restaurant Code</label>
                                        <input
                                            name="restaurant_code"
                                            type="text"
                                            className={
                                                'form-control' +
                                                (formik.errors.restaurant_code && formik.touched.restaurant_code
                                                    ? ' is-invalid'
                                                    : '')
                                            }
                                            onChange={formik.handleChange}
                                            value={formik.values.restaurant_code}
                                            placeholder="Enter 7 digit code"
                                        />
                                        <div className="invalid-feedback">
                                            {formik.errors.restaurant_code && formik.touched.restaurant_code
                                                ? formik.errors.restaurant_code
                                                : null}
                                        </div>
                                    </div>

                                    <div className="text-center py-3">
                                        <h3 className="mb-0">
                                            Or
                                        </h3>

                                    </div>
                                    {/* <div className="form-group mb-3">
                                        <label htmlFor="">City</label>
                                        <select className="form-control" value={cityId} onChange={e => setCityId(e.target.value)}>
                                            <option value="">Select City</option>
                                            {citys.map((city) => <option value={city.id}>{city.cityname}</option>)}
                                        </select>

                                    </div> */}

                                    <div className="form-group mb-3">
                                        <label htmlFor="">City </label>
                                        <SearchCity valueSelected={(e) => setCityId(e.id)} placeHolder={'Search City'} />
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="">Search </label>

                                        <Autocomplete valueSelected={restaurantSelected} cityId={cityId} isError={restaurantCodeError} />
                                    </div>

                                    {/* <div className="offer-info">
                                        <p >
                                            Fill in the required details
                                            to Unlock the Offer
                                        </p>
                                    </div> */}
                                    <div className="form-group mt-4 text-end">
                                        <button type="submit" className="btn-carlsberg">
                                            Submit
                                        </button>
                                    </div>



                                </form>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}


export default RestaurantCode;



