import { Routes, Route } from 'react-router-dom';
import Mobile from '../pages/onboarding/mobile/mobile';
import Otp from '../pages/onboarding/otp/otp';
import Register from '../pages/onboarding/register/register';
import WelcomeVideo from '../pages/onboarding/wecome-video/welcomeVideo';
import { routerPath } from './path';
import Splash from '../pages/onboarding/splash/splash';


const OnboardRoutes = () => {
    return (
        <Routes>
            <Route path='/*' element={<Splash />} options={{ gestureEnabled: false, swipeEnabled: false }}></Route>
            <Route path='*' element={<Splash />} options={{ gestureEnabled: false, swipeEnabled: false }}></Route>
            <Route path='/' element={<Splash />} options={{ gestureEnabled: false, swipeEnabled: false }}></Route>
            <Route path={routerPath.splash} element={<Splash />} options={{ gestureEnabled: false, swipeEnabled: false }}></Route>
            <Route path={routerPath.mobile} element={<Mobile />} options={{ gestureEnabled: false, swipeEnabled: false }}></Route>
            <Route path={routerPath.otp} element={<Otp />} options={{ gestureEnabled: false, swipeEnabled: false }}></Route>
            <Route path={routerPath.register} element={<Register />} options={{ gestureEnabled: false, swipeEnabled: false }}></Route>
            {/* <Route path={routerPath.welcomeVideo} element={<WelcomeVideo />}></Route> */}
        </Routes>
    )
}


export default OnboardRoutes;
