import { Button } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import './welcomeVideo.scss'
import ApiService from "../../../services/http/api-service";
import { ApiConfiguration } from "../../../services/http/api-configuration";
import { Navigate, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import logo from '../../../../assets/images/Carlsberg-logo.png'
import CommonHeader from "../../../components/common/common-header/common-header";

import { AuthorizationContext } from "../../../services/auth/AuthorizationContext";
import { useContext, useEffect, useRef, useState } from "react";
import { routerPath } from "../../../navigation/path";
import welcomeImg from '../../../../assets/images/welcome-img.png'
import playBtn from '../../../../assets/images/play-button.png'
import rightArrow from '../../../../assets/images/right-arrow.png'



const WelcomeVideo = () => {
    const { isAuthorized, login, logout } = useContext(AuthorizationContext);
    const [searchParams] = useSearchParams();
    const [bannerDetail, setBannerDetial] = useState()
    const location = useLocation()

    const name = searchParams.get('name')
    const navigate = useNavigate()
    const [videoUrl, setVideoUrl] = useState('')
    const queryParams = new URLSearchParams(location.search);
    const dataString = queryParams.get('offer_detail');
    const offerDetail = JSON.parse(decodeURIComponent(dataString));

    // const sendToNextPageByTime = setTimeout(() => {
    //     navigation(`${routerPath.offers}`)
    // }, 6000)


    const skipVideo = (e) => {
        console.log('Skip video button clicked');
        // login();
        navigate(`${routerPath.qrSuccess}?offer_detail=${encodeURIComponent(JSON.stringify(offerDetail))}`)
        // navigation(routerPath.offers);
        e.stopPropagation();
    };

    const playYoutubeVideo = () => {
        document.getElementById('youtubelink').click()
    }

    const getBannerImges = () => {
        ApiService.get(ApiConfiguration.welcomeContent).then(res => {
            console.log(res)
            setBannerDetial(res.data.data)
            setVideoUrl(res.data.data.video_link)
            console.log(res.data.data.video_link)
        }).catch(err => {

        })
    }

    const isPageLoadOnes = useRef(false)



    useEffect(() => {

        if (!isPageLoadOnes.current) {
            getBannerImges()
            isPageLoadOnes.current = true
        }
    }, [])
    return (
        <>

            <Container className="welcome-video-page">
                <Row>
                    <Col col={12}>
                        <div className="button-wrapper">
                            <button onClick={skipVideo}>
                                {/* <img src={rightArrow} alt="" /> */}
                                Skip
                            </button>
                        </div>
                    </Col>
                    <Col md={12} className="px-0">
                        <div className="img-wrapper">
                            <iframe className="welcome-video" width="326" height="800" src={videoUrl} title="Welcome Video" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )

}


export default WelcomeVideo;


