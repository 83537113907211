import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import backBtn from "../../../../assets/images/wrapper.png"
import { useNavigate } from "react-router-dom";
import "./about-us.scss"

const AboutUs = () => {
    const navigate = useNavigate()
    const goBack = () => {
        navigate(-1)
    }
    return (
        <>
            <Container className="about-us-page">
                <Row>
                    <Col md={12}>
                        <div className="back-btn-wrapper">
                            <div>
                                <img src={backBtn} alt="" onClick={goBack} />

                            </div>
                            <p>
                                About Us
                            </p>
                        </div>
                    </Col>


                    <Col md={12} className="px-3">
                        <div className="heading">
                            <h2>
                                About Us
                            </h2>
                        </div>
                        <div className="content">
                            <p><strong>About Carls Hoppy Hours Restaurant Program</strong></p>
                            <p>Welcome to Carls Hoppy Hours Restaurant Program, brought to you by Carlsberg India Pvt. Ltd. Our program offers an exciting opportunity for consumers to elevate their dining experiences while enjoying their favorite Carlsberg SKUs.</p>
                            <p><strong>Eligibility:</strong>Participants must be of legal drinking age as per the excise laws of their respective states. We ensure compliance with all applicable laws and regulations, prioritizing responsible consumption.</p>
                            <p><strong>How to Participate and Avail Offers:</strong>To participate, simply visit our partnered restaurants offering Carls Hoppy Hours and scan the QR code provided on marketing collaterals. Register on our program webapp, select your preferred offer, and present the generated QR code to the service provider at the restaurant.</p>
                            <p><span >Join us in celebrating great moments with Carls Hoppy Hours Restaurant Program. Elevate your dining experience with exclusive offers and unforgettable experiences. Cheers to good times with Carlsberg!</span></p>
                        </div>
                    </Col>
                </Row>
            </Container>

        </>
    )
}

export default AboutUs;