import axios from 'axios';
import { baseUrl } from '../http/api-configuration';
import { useContext } from 'react';
import { AuthorizationContext } from '../auth/AuthorizationContext';
import { useNavigate, Navigate } from 'react-router-dom';
import { routerPath } from '../../navigation/path';
import { redirect } from "react-router-dom";

let token = localStorage.getItem('auth_token')

export const axiosInstance = axios.create({
    baseURL: { baseUrl }, // Set your API base URL
    timeout: 5000, // Set the timeout value (optional)
    headers: {
        'Content-Type': 'application/json', // Set your desired request headers
        'Authorization': `${token == null ? '' : 'Bearer ' + token}`
    },
});
// 'Content-Type': 'application/json', 
// 'Content-Type': 'multipart/form-data',


axiosInstance.interceptors.request.use(
    config => {

        const token = localStorage.getItem('auth_token');
        if (token) {
            config.headers['Authorization'] = `Bearer ${JSON.parse(token)}`;
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);


axiosInstance.interceptors.response.use(
    response => {

        token = localStorage.getItem('auth_token')
        return response;

    },
    error => {
        console.log(error)
        if (error.response.data.status == 401) {
            localStorage.clear()
            Navigate("/splash");
            console.log('Unauthorized')
        }
        return Promise.reject(error);
    }
);




