
import { useContext, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import './my-profile.scss'
import { ToastContainer, toast } from 'react-toastify';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import backBtn from "../../../../assets/images/wrapper.png"
import user from "../../../../assets/images/user.png"
import pencial from "../../../../assets/images/pencial.png"
import { useNavigate } from 'react-router-dom';
import ApiService from "../../../services/http/api-service";
import { ApiConfiguration } from "../../../services/http/api-configuration";
import { FormConfig } from "../../../services/http/api-constant";
import CommonAlert from "../../../components/common/common-alert/common-alert";
import SelectWithSearch from "../../../components/common/select-with-search/SelectWithSearch";

const MyProfile = () => {
    // const { setAuth } = useContext(AuthContext)
    const navigate = useNavigate()
    const [selectedCity, setSelectedCity] = useState('')
    const userDetail = JSON.parse(localStorage.getItem('user_detail'))
    const validationSchema = Yup.object().shape({

        mobile: Yup.string()
            .required('mobile number is required')
            .min(10, 'mobile number must be at least 10 digit')
            .max(10, 'mobile number must not exceed 10 digit'),

        name: Yup.string()
            .required('Name is a mandatory field')
            .matches(/^[A-Za-zÀ-ÖØ-öø-ÿ'-\s]+$/, 'Please enter valid name'),

        age: Yup.number()
            .required('Age is a mandatory field')
            .positive('Age must be a positive number')
            .integer('Age must be an number')
            .test('valid-age', 'Invalid age', value => {
                return value > 17;
            }),
        city_id: Yup.string()
            .required('City is a mandatory field'),
    });

    const formik = useFormik({
        initialValues: {
            mobile: userDetail.customer_phone_number,
            name: '',
            age: '',
            city_id: ''
        },
        validationSchema,
        // validateOnChange: false,
        // validateOnBlur: false,
        onSubmit: (data) => {
            console.log(data)
            const formData = new FormData()
            formData.append('name', data.name)
            formData.append('age', data.age)
            formData.append('mobile_no', data.mobile)
            formData.append('city_id', data.city_id)
            if (profileImg != null) {
                if (typeof (profileImg) == 'object') {
                    formData.append('profile_image', profileImg)
                } else {
                    formData.append('profile_image', '')
                }
            } else {
                formData.append('profile_image', '')
            }

            ApiService.post(ApiConfiguration.editprofile, formData, FormConfig).then(res => {
                console.log(res);
                setalertMessege(`${res.data.data.customer_name} , Your personal profile updated successfully `)
                setProfileUpdate(true)
            }).catch(err => {
                console.log(err);
            })
        }
    });

    const [name, setName] = useState('');
    const [mobileNo, setMobileNo] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [dataCity, setCityData] = useState([]);
    const [isProfileUpdated, setProfileUpdate] = useState(false)
    const [alertMessege, setalertMessege] = useState('')
    const [profileImg, setProfileImg] = useState('')

    useEffect(() => {
        // Fetch city list from API    
        ApiService.get(ApiConfiguration.citylist).then(response => {
            const dataCity = response.data.data;
            setCityData(dataCity);
        }).catch(error => {
            console.error('Error fetching personal details:', error);
            setIsLoading(false);
        });
        // Fetch personal details from API     
        ApiService.get(ApiConfiguration.myprofile).then(response => {

            const data = response.data.data[0];
            setMobileNo(data.mobile_no)
            console.log(data)
            let formik_data = {
                mobile: data.mobile_no,
                name: data.customer_name,
                age: data.age,
                city_id: data.city_id == null ? '' : data.city_id
                // city_id: data.city_id == null ? '' : data.city_id
            }
            formik.setValues(formik_data);
            setIsLoading(false);
            setProfileImg(data.profile_image)
        }).catch(error => {
            console.error('Error fetching personal details:', error);
            setIsLoading(false);
        });
    }, []);


    const goBack = () => {
        navigate(-1)
    }


    const clickProfilePhoto = () => {
        document.getElementById('imageUpload').click()
    }
    const uploadProfileImage = (e) => {
        console.log(e.target.files[0])
        setProfileImg(e.target.files[0])
    }

    const closeAlert = () => {
        setProfileUpdate(false)
        goBack()
    }
    const citySelected = (e) => {
        console.log(e.target.value)
        formik.setFieldValue('city_id', e.target.value);
        setSelectedCity(e.target.value)

    }

    if (isLoading) {
        return <p>Loading...</p>;
    }
    return (
        <>
            {
                isProfileUpdated &&
                <CommonAlert messege={alertMessege} btn2={`OK`} btn2ClickEvent={closeAlert} isSingleBtn={true}></CommonAlert>
            }

            <Container className=" mx-0 my-profile" fluid>
                <Row>

                    <Col md={12}>
                        <div className="back-btn-wrapper">
                            <img src={backBtn} alt="" onClick={goBack} />
                        </div>
                        <div className="user-info">
                            <div className="user-img" onClick={clickProfilePhoto}>
                                <img src={profileImg == '' || profileImg == null ? user : profileImg} alt="" className="user-img" />
                                <img src={pencial} alt="" className="pencial-img" />
                                <input type="file" accept="image/*" name="image" hidden id="imageUpload" onChange={uploadProfileImage} />

                            </div>
                            <div className="user-name">
                                <p className="name">
                                    {name}
                                </p>
                                <p className="number">
                                    +91 {mobileNo}
                                </p>
                            </div>
                        </div>


                    </Col>
                    <Col md={12} className="px-0">

                        <div className="right-container">
                            <div className="form-wrapper">
                                {/* <form onSubmit={formik.handleSubmit}>

                                    <div className="form-group mb-3">
                                        <label htmlFor="">Mobile Number</label>
                                        <input
                                            name="mobile"
                                            type="tel"
                                            className={
                                                'form-control' +
                                                (formik.errors.mobile && formik.touched.mobile
                                                    ? ' is-invalid'
                                                    : '')
                                            }
                                            onChange={e => setMobileNo(e.target.value)}
                                            value={mobileNo}
                                            minLength="10"
                                            maxLength="10"
                                            placeholder="Phone number"
                                            disabled
                                        />
                                        <div className="invalid-feedback">
                                            {formik.errors.mobile && formik.touched.mobile
                                                ? formik.errors.mobile
                                                : null}
                                        </div>
                                    </div>


                                    <div className="form-group mb-3">
                                        <label htmlFor="">Name</label>

                                        <input
                                            name="name"
                                            type="text"
                                            className={
                                                'form-control' +
                                                (formik.errors.name && formik.touched.name
                                                    ? ' is-invalid'
                                                    : '')
                                            }
                                            onChange={formik.handleChange}
                                            value={formik.values.name}
                                            placeholder="Name"
                                        />
                                        <div className="invalid-feedback">
                                            {formik.errors.name && formik.touched.name
                                                ? formik.errors.name
                                                : null}
                                        </div>
                                    </div>
                                    <div className="form-group mb-3">
                                        <label htmlFor="">Age</label>

                                        <input
                                            name="age"
                                            type="tel"
                                            className={
                                                'form-control' +
                                                (formik.errors.age && formik.touched.age
                                                    ? ' is-invalid'
                                                    : '')
                                            }
                                            onChange={e => setAge(e.target.value)}
                                            value={age}
                                            minLength="1"
                                            maxLength="3"
                                            placeholder="Age"
                                            disabled
                                        />
                                        <div className="invalid-feedback">
                                            {formik.errors.age && formik.touched.age
                                                ? formik.errors.age
                                                : null}
                                        </div>
                                    </div>
                                    <div className="form-group mb-3">
                                        <label htmlFor="">City</label>


                                        <select onChange={e => setCityId(e.target.value)}
                                            value={cityId}
                                            className={
                                                'form-control' +
                                                (formik.errors.city && formik.touched.city
                                                    ? ' is-invalid'
                                                    : '')
                                            } name="city">
                                            <option value=""> Select City </option>
                                            {dataCity.map((city) => <option value={city.id}>{city.cityname}</option>)}
                                        </select>
                                        <div className="invalid-feedback">
                                            {formik.errors.city && formik.touched.city
                                                ? formik.errors.city
                                                : null}
                                        </div>
                                    </div>

                                    <div className="form-group mt-4 text-end">
                                        <button type="submit" className="btn-carlsberg" >
                                            Submit
                                        </button>
                                    </div>



                                </form> */}

                                <form onSubmit={formik.handleSubmit}>

                                    <div className="form-group mb-3">
                                        <label htmlFor="">Mobile Number</label>
                                        <input
                                            name="mobile"
                                            type="tel"
                                            className={
                                                'form-control' +
                                                (formik.errors.mobile && formik.touched.mobile
                                                    ? ' is-invalid'
                                                    : '')
                                            }
                                            onChange={formik.handleChange}
                                            value={formik.values.mobile}
                                            minLength="10"
                                            maxLength="10"
                                            placeholder="Phone number"
                                            disabled
                                        />
                                        <div className="invalid-feedback">
                                            {formik.errors.mobile && formik.touched.mobile
                                                ? formik.errors.mobile
                                                : null}
                                        </div>
                                    </div>
                                    <div className="form-group mb-3">
                                        <label htmlFor="">Name</label>

                                        <input
                                            name="name"
                                            type="text"
                                            className={
                                                'form-control' +
                                                (formik.errors.name && formik.touched.name
                                                    ? ' is-invalid'
                                                    : '')
                                            }
                                            onChange={formik.handleChange}
                                            value={formik.values.name}
                                            placeholder="Name"
                                        />
                                        <div className="invalid-feedback">
                                            {formik.errors.name && formik.touched.name
                                                ? formik.errors.name
                                                : null}
                                        </div>
                                    </div>

                                    <div className="form-group mb-3">
                                        <label htmlFor="">Age</label>

                                        <input
                                            name="age"
                                            type="number"
                                            className={
                                                'form-control' +
                                                (formik.errors.age && formik.touched.age
                                                    ? ' is-invalid'
                                                    : '')
                                            }
                                            onChange={formik.handleChange}
                                            value={formik.values.age}
                                            minLength="1"
                                            maxLength="3"
                                            placeholder="Age"
                                        />
                                        <div className="invalid-feedback">
                                            {formik.errors.age && formik.touched.age
                                                ? formik.errors.age
                                                : null}
                                        </div>
                                    </div>

                                    {/* <div className="form-group">
                                        <label htmlFor="">City </label>
                                        <SelectWithSearch valueSelected={citySelected} placeHolder={'Search City'}
                                            className={

                                                (formik.errors.city_id && formik.touched.city_id
                                                    ? ' is-invalid'
                                                    : '')
                                            } name="city_id" />
                                        <div className="invalid-feedback">
                                            {
                                                formik.errors.city_id && formik.touched.city_id
                                                    ? formik.errors.city_id
                                                    : null
                                            }
                                        </div>
                                    </div> */}

                                    <div className="form-group mb-3">
                                        <label htmlFor="">City</label>


                                        <select onChange={citySelected}
                                            value={formik.values.city_id}
                                            className={
                                                'form-control' +
                                                (formik.errors.city_id && formik.touched.city_id
                                                    ? ' is-invalid'
                                                    : '')
                                            } name="city">
                                            <option value=""> Select City </option>
                                            {dataCity.map((city) => <option value={city.id}>{city.cityname}</option>)}
                                        </select>
                                        <div className="invalid-feedback">
                                            {formik.errors.city && formik.touched.city
                                                ? formik.errors.city_id
                                                : null}
                                        </div>
                                    </div>




                                    <div className="form-group mt-4 text-end">

                                        <button type="submit" className="btn-carlsberg">
                                            Submit
                                        </button>
                                    </div>



                                </form>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default MyProfile;