import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import './offers.scss'
import { useEffect, useRef, useState } from "react";
import offerThumbnil from '../../../assets/images/offer-thabnil.png'
import { useNavigate } from "react-router-dom";
import Navbar from "../../components/common/navbar/navbar";
import { routerPath } from "../../navigation/path";
import ApiService from "../../services/http/api-service";
import { ApiConfiguration } from "../../services/http/api-configuration";
import CommonCarousel from "../../components/common/carousel/common-carousel";
import locationImg from '../../../assets/images/location.png'
const Offers = () => {

    const [offerList, setOfferList] = useState([])
    const [redeeedOfferList, setRedeemedOfferList] = useState([])
    const [bannerImg, setBannerImg] = useState({})
    const navigate = useNavigate()
    const redeemOffer = (item) => {
        navigate(`${routerPath.redeemNow}?offer_id=${item.offer_id}`)
    }

    const isApiLoadOnes = useRef(false)

    useEffect(() => {
        if (!isApiLoadOnes.current) {
            getBannerImges()
            getRedeemedOffers()
            getOfferList()
            isApiLoadOnes.current = true
        }
    }, [])

    const getBannerImges = () => {
        ApiService.get(ApiConfiguration.dashboardBanner + `?rest_code=${localStorage.getItem('restaurant_code')}`).then(res => {
            console.log(res)
            setBannerImg(res.data.data)
        }).catch(err => {

        })
    }




    const getOfferList = () => {
        ApiService.get(ApiConfiguration.offerList + `?restaurant_code=${localStorage.getItem('restaurant_code')}&offer_id=`).then(res => {
            //console.log(res)
            setOfferList(res.data.data)
        }).catch(err => {
            console.log(err)
        })
    }
    const getRedeemedOffers = () => {
        ApiService.get(ApiConfiguration.redeemedList + `?restaurant_code=${localStorage.getItem('restaurant_code')}`).then(res => {
            console.log(res + "redeemed offers")
            setRedeemedOfferList(res.data.data)
        }).catch(err => {
            console.log(err)
        })
    }
    console.log(redeeedOfferList)
    return (
        <>
            <Navbar></Navbar>
            <Container className="offers-page">
                <Row>
                    <Col md={12}>
                        <div className="user-info">
                            <h3>
                                Hi {bannerImg.customer_name} !
                            </h3>
                            <p>
                                <img src={locationImg} alt="" /> {bannerImg?.restaurant_name}
                            </p>
                        </div>
                    </Col>

                    <Col md={12}>
                        {
                            bannerImg.banner_details &&
                            <CommonCarousel bannerImg={bannerImg?.banner_details} />
                        }
                    </Col>


                    <Col md={12}>
                        {
                            offerList.map((item) => (

                                <div className="offer-wrapper" onClick={() => redeemOffer(item)}>
                                    <div className="offer-img-wrapper">
                                        <img src={item.offer_image} alt="" />
                                        <div className="short-offer">
                                            {item.short_description}
                                        </div>
                                    </div>
                                    <div className="offer-info-wrapper">
                                        <p className="offer-name">
                                            {item.offer_name}
                                        </p>
                                        <p className="valid-date">
                                            Offer valid till {item.validity}
                                        </p>

                                    </div>
                                </div>

                            ))
                        }

                    </Col>







                </Row>
            </Container>
        </>
    )
}

export default Offers;