import './qr-success.scss'
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import qrImage from "../../../assets/images/qrcode.png"
import { useLocation, useNavigate } from 'react-router-dom';
import CommonHeader from '../../components/common/common-header/common-header';
import { routerPath } from '../../navigation/path';
import { useEffect, useRef, useState } from 'react';
import ApiService from '../../services/http/api-service';
import { ApiConfiguration } from '../../services/http/api-configuration';
import { FormConfig } from '../../services/http/api-constant';
import CommonToast from '../../components/common/toast/common-toast';
import offerSuccessImg from '../../../assets/images/offer-success-img.png';
import carlsLogo from '../../../assets/images/Carlsberg-logo.png'
import CloseBtn from '../../../assets/images/Close.png'
const QrSuccess = () => {
    const location = useLocation();
    const navigate = useNavigate()
    const [isCommonToast, setCommonToast] = useState(false)
    const [toastMessege, settoastMessege] = useState('')

    const queryParams = new URLSearchParams(location.search);
    const dataString = queryParams.get('offer_detail');
    const offerDetail = JSON.parse(decodeURIComponent(dataString));
    console.log(offerDetail)
    const [qrDetail, setQrDetail] = useState({})

    const restorantCode = () => {
        navigate(routerPath.restaurantCode)
    }

    const isApiLoadOnes = useRef(false)


    useEffect(() => {
        if (!isApiLoadOnes.current) {
            getQrCode()
            isApiLoadOnes.current = true
        }
    }, [])

    const getQrCode = () => {
        const formData = new FormData()
        formData.append('restaurant_offer_id', offerDetail.restaurant_offer_id)
        ApiService.post(ApiConfiguration.qrSuccess, formData, FormConfig).then(res => {
            console.log(res)
            setQrDetail(res.data.data)
        }).catch(err => {
            console.log(err.response.data.data.message)
            settoastMessege(err.response.data.data.message)
            setCommonToast(true)
            removeToast()
            navigate(routerPath.offers)
        })
    }

    const removeToast = () => {
        setTimeout(() => {
            setCommonToast(false)
        }, 3000)
    }

    const ClickBackBtn = () => {
        navigate(routerPath.offers)
    }


    return (
        <>
            {
                isCommonToast &&
                <CommonToast message={toastMessege} />
            }
            {/* <CommonHeader header={'Offer Details'}></CommonHeader> */}
            <div className='common-header'>
                <h3>
                    Offer Details
                </h3>
                <img src={CloseBtn} alt="" onClick={ClickBackBtn} />

            </div>
            <Container className='qr-success-page'>
                <Row>
                    <Col md={12}>
                        <div className='text-center mt-5'>
                            <img src={carlsLogo} alt="" />
                        </div>
                        <div className="mt-3 text-center">
                            <h4>
                                Congratulations!
                            </h4>
                            <p className='activated-offer'>
                                You have activated the following offer
                            </p>
                            <p className='offer-name'>
                                {offerDetail?.offer_name}
                            </p>
                        </div>
                    </Col>

                    <Col md={12}>

                        {/* <div className="mt-2 text-center">
                            <img src={qrDetail.link} alt="" />
                        </div> */}
                        <div className="mt-2 text-center rounded-50">
                            <img src={offerSuccessImg} alt="" />
                        </div>
                        <div className='offer-code-detail'>
                            <p className='date'>
                                {qrDetail?.date_time}
                            </p>
                            <p className='offer-code'>
                                {qrDetail?.unique_code}
                            </p>
                        </div>
                    </Col>

                    <Col md={12}>

                        <div className="mt-3 text-center">
                            <p className='show-qr-info'>
                                {/* Show the above QR code to the
                                server to avail the offer */}
                                Show the above to the server to avail the offer.

                            </p>
                        </div>

                        <div className="form-group mt-4 text-center">
                            Unable to avail the offer ?
                            <button type="button" onClick={restorantCode} className="resend-btn">
                                click here
                            </button>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default QrSuccess;