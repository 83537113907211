
import { useEffect } from "react";
import { useLoader } from "../../../services/loader/loader";
import { load } from "../../../../assets/images/Spinner-1s-200px.gif"
import loginBanner from "../../../../assets/images/login_banner.png"
import './customLoader.scss'
const CustomLoader = () => {
    const { loading } = useLoader();
    useEffect(() => {
        console.log(loading)
    })
    return (
        <>
            {
                loading &&
                <div className="loader-container">
                    <img src={require('../../../../assets/images/Spinner-1s-200px.gif')} alt="" />
                    {/* loading */}
                </div>
            }

        </>
    )
}

export default CustomLoader